import React from 'react';
import { Card, Col, Row } from "@themesberg/react-bootstrap";
import { WidgetWithAvatarComponent } from "../../../../components/WidgetWithAvatar/WidgetWithAvatarComponent";
import beneficiaryBulletinLottieAnimation from "../../../../assets/lottieAnimations/office/36678-check-list.json";
import i18next from "../../../../translations/i18nConfigInstance";
import { createUniformSchema } from "../../../../utils/uniformsSchemaBridgeGenerator";
import { AutoField, AutoForm } from "uniforms-bootstrap5";
import { beneficiaryGeneralDataSchema } from "../../../../schema/beneficiaryFormSchemas/nested/beneficiaryGeneralDataSchema";
import opsYtkePublicApiAxiosInstance from "_services/OpsYtkePublicApi";

class BeneficiaryPersonalDataFormComponent extends React.Component {
    _beneficiaryGeneralDataFormRef = null;
    _fullStateRegionList = [];

    _beneficiaryGeneralDataUniformSchema = createUniformSchema(beneficiaryGeneralDataSchema);

    state = {
        municipalities: [],
    };

    async componentDidMount() {
        const { registerUniformRefCallback } = this.props;
        registerUniformRefCallback(this._beneficiaryGeneralDataFormRef);
        try {
            const response = await opsYtkePublicApiAxiosInstance.get('/domain/state');
            const data = response.data;
            this._fullStateRegionList = data;
            const municipalities = [];
            data.forEach(state => {
                state.municipality?.forEach(m => {
                    municipalities.push({
                        label: m.name,
                        value: m.name,
                        id: m.id,
                        state: {
                            id: state.id,
                            name: state.name,
                            nameEn: state.nameEn
                        },
                        nameEn: m.nameEn || m.name
                    });
                });
            });
            const seen = new Set();
            const uniqueMunicipalities = municipalities.filter(m => {
                if (seen.has(m.value)) return false;
                seen.add(m.value);
                return true;
            });
            this.setState({ municipalities: uniqueMunicipalities });
        } catch (error) {
            console.error("Failed to fetch municipalities:", error);
        }
    }

    componentWillUnmount() {
        this._beneficiaryGeneralDataFormRef = null;
    }

    updateParentModel = (data) => {
        const { updateModelParentCallback } = this.props;
        updateModelParentCallback(data);
    };

    updateModelCallback = (model) => {
        const { municipalities } = this.state;
        const selectedMunicipality = model.municipality;
        const matchedMunicipality = municipalities.find(m => m.value === selectedMunicipality);
        if (matchedMunicipality) {
            model.municipality = {
                id: matchedMunicipality.id,
                name: matchedMunicipality.label,
                nameEn: matchedMunicipality.nameEn,
                state: matchedMunicipality.state
            };
        }
        this.updateParentModel(model);
    };

    render() {
        const { isViewMode, isEditMode, isCreateMode, recordData } = this.props;
        const { municipalities } = this.state;
        const states = this._fullStateRegionList.map(state => ({
            label: state.name,
            value: state.name,
            id: state.id,
            nameEn: state.nameEn
        }));
        const sanitizedModel = {
            ...recordData,
            state: typeof recordData?.municipality === 'object'
                ? recordData?.municipality?.state?.name
                : recordData?.state
                    ? String(recordData?.state)
                    : null,
            municipality: typeof recordData?.municipality === 'object'
                ? recordData?.municipality?.name
                : typeof recordData?.municipality === 'number'
                    ? String(recordData?.municipality)
                    : recordData?.municipality || null
        };
        const filteredMunicipalities = sanitizedModel.state
            ? municipalities.filter(m => m.state.name === sanitizedModel.state)
            : [];
        const dynamicSchema = {
            ...beneficiaryGeneralDataSchema,
            properties: {
                ...beneficiaryGeneralDataSchema.properties,
                ...(states.length > 0 && {
                    state: {
                        label: i18next.t("beneficiaryForm.familyDetails.affiliatedMembers.state") || "Περιφερειακή Ενότητα",
                        type: "string",
                        enum: states.map(s => s.value),
                        uniforms: {
                            options: states.map(s => ({ label: s.label, value: s.value })),
                            placeholder: i18next.t("generic.pleaseSelect")
                        }
                    }
                }),
                ...(filteredMunicipalities.length > 0 && {
                    municipality: {
                        ...beneficiaryGeneralDataSchema.properties?.municipality,
                        label: i18next.t("beneficiaryForm.familyDetails.affiliatedMembers.municipality") || "Δήμος",
                        type: "string",
                        enum: filteredMunicipalities.map(m => m.value),
                        uniforms: {
                            options: filteredMunicipalities.map(m => ({ label: m.label, value: m.value })),
                            placeholder: i18next.t("generic.pleaseSelect")
                        }
                    }
                })
            }
        };
        const dynamicUniformSchema = createUniformSchema(dynamicSchema);
        return (
            <Row>
                <Col xs={12} xl={8}>
                    <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>
                            <Row className="autoFormCustom">
                                <Col xs={12} xl={12}>
                                    <AutoForm
                                        ref={(form) => { this._beneficiaryGeneralDataFormRef = form; }}
                                        schema={dynamicUniformSchema}
                                        onChangeModel={(model) => this.updateModelCallback(model)}
                                        model={sanitizedModel}
                                        showInlineError
                                        placeholder
                                    >
                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"amka"} />
                                            </Col>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"afm"} readOnly disabled />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"lastName"} readOnly disabled />
                                            </Col>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"firstName"} readOnly disabled />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"gender"} />
                                            </Col>
                                            <Col xs={12} xl={6}>
                                                <AutoField name={"state"} />
                                            </Col>
                                        </Row>
                                        { sanitizedModel.state && (
                                            <Row>
                                                <Col xs={12} xl={6}>
                                                    <AutoField name={"municipality"} />
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className="mt-4 mb-4">
                                            <Col xs={12} xl={4}>
                                                <AutoField name={"birthDate"} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} xl={4}>
                                                <AutoField name={"alive"} />
                                            </Col>
                                        </Row>
                                    </AutoForm>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} xl={4}>
                    <Row>
                        <Col xs={12}>
                            <WidgetWithAvatarComponent
                                title={i18next.t("beneficiaryForm.page.beneficiary")}
                                subtitle={recordData?.firstName + " " + recordData?.lastName}
                                lottieAnimationSourceFile={beneficiaryBulletinLottieAnimation}
                                animationWidth={130}
                                animationHeight={130}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default BeneficiaryPersonalDataFormComponent;
