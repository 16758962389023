import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from '@themesberg/react-bootstrap';
import i18next from "../../translations/i18nConfigInstance";
import reportService from "_services/report/reportService";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

class ChartPage extends React.Component {
	state = {
		submissionPerCategory: {
			labels: [],
			datasets: [
				{
					label: '# of Votes',
					data: [],
					backgroundColor: [],
					borderColor: [],
					borderWidth: 1,
				},
			],
		},
		accountsPerGender: {
			labels: [i18next.t("report.gender.title")],
			datasets: [
				{
					label: i18next.t("report.gender.MALE"),
					data: [],
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
				},
				{
					label: i18next.t("report.gender.FEMALE"),
					data: [],
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				},
				{
					label: i18next.t("report.gender.OTHER"),
					data: [],
					backgroundColor: 'rgba(255, 205, 86, 0.5)',
				},
			],
		},
		accountsPerAge: {
			labels: [],
			datasets: [
				{
					label: '# of Votes',
					data: [],
					backgroundColor: [],
					borderColor: [],
					borderWidth: 1,
				},
			],
		},
		accountsPerFamily: {
			labels: [i18next.t("report.maritalStatus.title")],
			datasets: [
				{
					label: i18next.t("report.maritalStatus.maried"),
					data: [],
					backgroundColor: 'rgba(53, 162, 235, 0.5)',
				},
				{
					label: i18next.t("report.maritalStatus.noMaried"),
					data: [],
					backgroundColor: 'rgba(255, 99, 132, 0.5)',
				},
			],
		},
		// New state properties for Municipality and State charts
		municipalityData: {
			labels: [],
			datasets: [
				{
					label: i18next.t("report.municipality.title"), // ensure you have a proper i18n key or use a literal string like "Municipality"
					data: [],
					backgroundColor: [],
					borderColor: [],
					borderWidth: 1,
				},
			],
		},
		stateData: {
			labels: [],
			datasets: [
				{
					label: i18next.t("report.state.title"), // ensure you have a proper i18n key or use "State"
					data: [],
					backgroundColor: [],
					borderColor: [],
					borderWidth: 1,
				},
			],
		},
	};

	randomIntFromInterval(min, max) { // min and max included
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	getHomePageReport() {
		// Gender Data
		reportService.byGender()
			.then((response) => {
				// Copy the state object (or clone it) to avoid direct mutation
				let tmp = { ...this.state.accountsPerGender };

				// Loop over each returned element and update the corresponding dataset
				response.data.forEach((element) => {
					let gender = element[0].toUpperCase();
					const count = element[1];
					if (gender === "MALE") {
						tmp.datasets[0].data[0] = count;
					} else if (gender === "FEMALE") {
						tmp.datasets[1].data[0] = count;
					} else if (gender === "OTHER") {
						tmp.datasets[2].data[0] = count;
					}
				});
				this.setState({ accountsPerGender: tmp });
			})
			.catch((error) => {
				console.log("Error getting byGender Data: ", error);
			});

		// Marital Status Data
		reportService.byMaritalStatus()
			.then((response) => {
				let tmp = this.state.accountsPerFamily;
				tmp.datasets[0].data[0] = response.data[0][0];
				tmp.datasets[1].data[0] = response.data[0][1];
				this.setState({ accountsPerFamily: tmp });
			})
			.catch((error) => {
				console.log("Error getting byMaritalStatus Data: ", error);
			});

		// Application Type Data
		reportService.byApplicationType()
			.then((response) => {
				let tmp = this.state.submissionPerCategory;
				let counter = 1;
				response.data.forEach(element => {
					tmp.labels.push(element[0]);
					tmp.datasets[0].data.push(element[1]);
					tmp.datasets[0].backgroundColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 0.2)"
					);
					tmp.datasets[0].borderColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 1)"
					);
					counter++;
				});
				this.setState({ submissionPerCategory: tmp });
			})
			.catch((error) => {
				console.log("Error getting byApplicationType Data: ", error);
			});

		// Age Group Data
		reportService.byAgeGroup()
			.then((response) => {
				let tmp = this.state.accountsPerAge;
				let counter = 1;
				response.data.forEach(element => {
					tmp.labels.push(i18next.t("report.ageGroup." + element[1]));
					tmp.datasets[0].data.push(element[0]);
					tmp.datasets[0].backgroundColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 0.2)"
					);
					tmp.datasets[0].borderColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 1)"
					);
					counter++;
				});
				this.setState({ accountsPerAge: tmp });
			})
			.catch((error) => {
				console.log("Error getting byAgeGroup Data: ", error);
			});

		// New: Municipality Data
		reportService.byMunicipality()
			.then((response) => {
				let tmp = this.state.municipalityData;
				let counter = 1;
				response.data.forEach(element => {
					tmp.labels.push(element[0]);
					tmp.datasets[0].data.push(element[1]);
					tmp.datasets[0].backgroundColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 0.2)"
					);
					tmp.datasets[0].borderColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 1)"
					);
					counter++;
				});
				this.setState({ municipalityData: tmp });
			})
			.catch((error) => {
				console.log("Error getting municipality Data: ", error);
			});

		// New: State Data
		reportService.byState()
			.then((response) => {
				let tmp = this.state.stateData;
				let counter = 1;
				response.data.forEach(element => {
					tmp.labels.push(element[0]);
					tmp.datasets[0].data.push(element[1]);
					tmp.datasets[0].backgroundColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 0.2)"
					);
					tmp.datasets[0].borderColor.push(
						"rgba(" + this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", " +
						this.randomIntFromInterval(counter, 255) + ", 1)"
					);
					counter++;
				});
				this.setState({ stateData: tmp });
			})
			.catch((error) => {
				console.log("Error getting state Data: ", error);
			});
	}

	componentDidMount() {
		this.getHomePageReport();
	}

	render() {
		const {
			accountsPerGender,
			accountsPerAge,
			submissionPerCategory,
			accountsPerFamily,
			municipalityData,
			stateData
		} = this.state;

		return (
			<div className="container pt-5 pb-5">
				<div className="row">
					<Row className="justify-content-md-center">
						<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
							<Bar
								options={{
									responsive: true,
									plugins: {
										legend: { position: 'top' }
									},
								}}
								data={accountsPerGender}
							/>
						</Col>
					</Row>

					{submissionPerCategory.datasets[0].data.length > 0 && (
						<>
							<Row className="justify-content-md-center">
								{i18next.t("report.submissionType.title")}
							</Row>
							<Row className="justify-content-md-center">
								<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
									<Pie data={submissionPerCategory} />
								</Col>
							</Row>
						</>
					)}

					{accountsPerAge.datasets[0].data.length > 0 && (
						<>
							<Row className="justify-content-md-center">
								{i18next.t("report.ageGroup.title")}
							</Row>
							<Row className="justify-content-md-center">
								<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
									<Pie data={accountsPerAge} />
								</Col>
							</Row>
						</>
					)}

					{/* New: Municipality Chart */}


					{/* New: State Chart */}
					{stateData.datasets[0].data.length > 0 && (
						<>
							<Row className="justify-content-md-center">
								{i18next.t("report.state.title")}
							</Row>
							<Row className="justify-content-md-center">
								<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
									<Pie data={stateData} />
								</Col>
							</Row>
						</>
					)}

					{municipalityData.datasets[0].data.length > 0 && (
						<>
							<Row className="justify-content-md-center">
								{i18next.t("report.municipality.title")}
							</Row>
							<Row className="justify-content-md-center">
								<Col xs={6} sm={6} xl={6} className="mt-5 mb-5 pt-5 pb-5">
									<Pie data={municipalityData} />
								</Col>
							</Row>
						</>
					)}
					{/* Uncomment if needed for Family Chart */}
					{/* <Row className="justify-content-md-center">
						<Col xs={12} sm={12} xl={4} className="mt-5 mb-5 pt-5 pb-5">
							<Bar
								options={{
									responsive: true,
									plugins: {
										legend: { position: 'top' }
									},
								}}
								data={accountsPerFamily}
							/>
						</Col>
					</Row> */}
				</div>
			</div>
		);
	}
}

export default ChartPage;
